<template>
  <div class="search-couriers">
    <b-autocomplete
      ref="autocomplete"
      v-model="searchTerm"
      :data="filteredCouriers"
      field="name"
      :placeholder="$t('assignment.rightPanel.assign.searchCouriers.placeholder')"
      icon="search"
      openOnFocus
      clearable
      @select="onSelect">
      <template slot="empty">
        {{ $t('assignment.rightPanel.assign.searchCouriers.noResults') }}
      </template>
    </b-autocomplete>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'search-couriers',
  props: {
    selectedCourier: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      searchTerm: ''
    }
  },
  computed: {
    ...mapGetters('courier', [
      'couriers'
    ]),
    filteredCouriers() {
      return this.couriers.filter(courier => {
        return RegExp(this.searchTerm, 'i').test(courier.name)
      })
    }
  },
  watch: {
    selectedCourier: {
      immediate: true,
      handler() {
        // use nextTick to wait for autocomplete to render
        this.$nextTick(() => {
          if (this.selectedCourier) {
            this.$refs.autocomplete.setSelected(this.selectedCourier)
          } else {
            this.searchTerm = ''
          }
        })
      }
    }
  },
  methods: {
    onSelect(option) {
      this.$emit('update:selectedCourier', option)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-couriers {
  &::v-deep {
    input,
    span.icon {
      @extend %body;
    }
  }
}
</style>
